<template>
  <div class="trend_tracking">
    <el-card style="margin-bottom: 20px; border-radius: 10px">
      <h2 class="insight_title" style="margin-bottom: 10px">筛选</h2>
      <el-form ref="ruleForm" size="small" :model="form" label-width="100px">
        <el-row>
          <el-col class="screen_box">
            <el-form-item
              required
              label="品类"
              prop="category"
              class="category"
              :rules="[{ required: true, message: '请选择品类' }]"
            >
              <el-select
                @change="productChange"
                v-model="form.category"
                placeholder="请选择品类"
              >
                <el-option
                  v-for="item in product"
                  :key="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              required
              label="产品分类"
              prop="productType"
              class="classification"
              :rules="[{ required: true, message: '请选择产品分类' }]"
            >
              <el-select
                v-model="form.productType"
                placeholder="请选择产品分类"
              >
                <el-option-group
                  v-for="group in classify"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :offset="22">
            <el-button
              type="primary"
              :disabled="axiosLoading"
              @click="getTableList()"
              >确定
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card style="margin-bottom: 20px; border-radius: 10px">
      <div class="table_box">
        <div class="explain_box">
          <div class="explain">
            <svg-icon
              class-name="tendency_question"
              class="icon tendency_question"
            >
            </svg-icon>
            <div class="outstanding">
              <div class="allege">
                <svg-icon class-name="comparison" class="icon"> </svg-icon>
              </div>
              <span>文字宣称指标显著</span>
            </div>
            <div class="outstanding">
              <div class="allege number_allege">
                <svg-icon class-name="comparison" class="icon"> </svg-icon>
              </div>
              <span>数字宣称指标显著</span>
            </div>
            <div class="outstanding">
              <div class="permeate">
                <svg-icon
                  class-name="tendency_arrow_reduce"
                  class="icon reduce"
                >
                </svg-icon>
                <svg-icon class-name="tendency_arrow_rise" class="icon rise">
                </svg-icon>
              </div>
              <span>提及率变化显著（≤-10% or ≥10%）</span>
            </div>
          </div>
          <el-button size="small" @click="detailsListEntrance()" type="primary"
            >查看详情</el-button
          >
        </div>
        <tendency-table
          :resourceData="resourceData"
          :defaultValue="form"
          :notHaveNumeric="notHaveNumeric"
          :loading="loading"
        ></tendency-table>
      </div>
    </el-card>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { trackingFilter, filtrateParams } from "@/api/tendency";
import tendencyTable from "./tendencyTable.vue";
import { ElMessage } from "element-plus";

const router = useRouter();
const ruleForm = ref();
const loading = ref(false);
const notHaveNumeric = ref([]);
let categoryProduct = null;
const product = ref([]);
const classify = ref([]);
const form = ref({});
const resourceData = ref([]);
const isExistParams = ref(false);
const screenParams = ref({});

const getParams = async () => {
  const {
    data: { categoryProductType, notHaveNumericClaim },
  } = await filtrateParams();

  //没有数字宣称的部分
  notHaveNumeric.value = notHaveNumericClaim;

  //品类
  categoryProduct = categoryProductType;
  product.value = Object.keys(categoryProductType);

  await setClassifyResult(product.value[0]);
  await setInitialValue();
};

const setInitialValue = () => {
  if (isExistParams.value) {
    form.value = screenParams.value;
    afterEntry(form.value);
  }
};

//设置产品分类下拉内容
const setClassifyResult = (val) => {
  classify.value = [];
  const current = categoryProduct[val];
  for (let key in current) {
    classify.value.push({
      label: current[key].length > 1 ? key : "",
      options: current[key].map((item) => {
        return {
          value: key + "||" + item,
          label: item,
        };
      }),
    });
  }
};

//品类Change事件
const productChange = (val) => {
  form.value.productType = "";
  setClassifyResult(val);
};

//筛选表格数据
const afterEntry = async (params) => {
  loading.value = true;
  const { data } = await trackingFilter({
    category: params.category,
    productType: params.productType,
  });
  resourceData.value = data.map((item, index) => {
    if (index !== 0) {
      const claim1 = item.claimLevel1.substring(
        item.claimLevel1.lastIndexOf("||")
      );
      const claim2 = item.claimLevel2.substring(
        item.claimLevel2.lastIndexOf("||")
      );
      item.claimLevel1 = item.claimLevel1.replace(claim1, "");
      item.claimLevel2 = item.claimLevel2.replace(claim2, "");
    }
    return item;
  });
  loading.value = false;
};

//首次进入页面
const initializePage = () => {
  screenParams.value = JSON.parse(window.sessionStorage.getItem("TENDENCY"));
  if (screenParams.value) {
    isExistParams.value = true;
  }
  getParams();
};
initializePage();

//确定按钮-筛选表格数据
const getTableList = () => {
  ruleForm.value.validate((valid) => {
    if (valid) {
      afterEntry(form.value);
    }
  });
};

//进入详情页面
const detailsListEntrance = () => {
  if (!resourceData.value.length) {
    return ElMessage({
      message: "请选择筛选条件",
      type: "warning",
    });
  }
  router.push({
    path: "/trendTrackingDetails",
    query: {
      category: form.value.category,
      productType: form.value.productType,
    },
  });
};

router.beforeEach((to, form) => {
  console.log(to);
  if (form.name == "TrendTracking") {
    sessionStorage.removeItem("TENDENCY");
  }
});
</script>
<style lang="scss" scoped>
.trend_tracking {
  .table_box {
    color: #777777;
    padding: 0 58px;

    .explain_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .explain {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .tendency_question {
          font-size: 22px;
        }
      }
    }

    .outstanding {
      margin-left: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;

      .allege {
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: #32a3be;
        background-color: transparent;
        color: #32a3be;
        font-size: 20px;
      }

      .allege.number_allege {
        color: #f56c6c;
      }

      span {
        margin-left: 8px;
      }

      .permeate {
        font-size: 18px;

        .reduce {
          color: #f56c6c;
        }

        .rise {
          color: #67c23a;
        }
      }
    }
  }

  .el-button--primary {
    background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
  }

  .insight_title {
    font-size: 20px;
  }

  .screen_box {
    display: flex;
    justify-content: flex-start;

    .category {
      width: 364px;
    }

    .classification {
      width: 390px;
    }
  }
}
</style>
<style lang="scss">
.trend_tracking {
  .el-table th.el-table__cell > .cell {
    font-weight: 700;
  }

  .el-table__body {
    .el-table__row:nth-child(1) {
      .cell {
        font-weight: 700;

        div {
          font-weight: 700;
        }
      }
    }
  }
}
</style>
