<template>
  <div>
    <el-table
      class="trend_tracking_table"
      v-loading="props.loading"
      :data="props.resourceData"
      :header-cell-style="headerCellStyle"
      :cell-style="cellStyle"
      :span-method="arraySpanMethod"
      @cell-click="cellClick"
    >
      <el-table-column>
        <el-table-column>
          <el-table-column width="200" prop="claimLevel1" align="center" />
          <el-table-column width="174" prop="claimLevel2" align="center" />
        </el-table-column>
      </el-table-column>
      <el-table-column label="文字宣称" align="center">
        <el-table-column label="效果指数" align="center">
          <el-table-column
            width="162"
            prop="verbalClaimEffectivenessP1"
            align="center"
          >
            <template #default="{ row }">
              <div v-if="row.verbalClaimEffectivenessP1.startsWith('P')">
                {{ row.verbalClaimEffectivenessP1 }}
              </div>
              <div
                class="allege"
                v-else-if="row.verbalClaimEffectivenessP1 > 1.05"
              >
                <svg-icon class-name="comparison" class="icon"> </svg-icon>
              </div>
              <div v-else></div>
            </template>
          </el-table-column>
          <el-table-column
            width="162"
            prop="verbalClaimEffectivenessP2"
            align="center"
          >
            <template #default="{ row }">
              <div v-if="row.verbalClaimEffectivenessP2.startsWith('P')">
                {{ row.verbalClaimEffectivenessP2 }}
              </div>
              <div
                class="allege"
                v-else-if="row.verbalClaimEffectivenessP2 > 1.05"
              >
                <svg-icon class-name="comparison" class="icon"> </svg-icon>
              </div>
              <div v-else></div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="提及率变化">
          <el-table-column
            width="106"
            prop="verbalClaimPenetrationChange"
            align="center"
          >
            <template #default="{ row }">
              <svg-icon
                v-if="
                  row.verbalClaimPenetrationChange > 0 &&
                  row.verbalClaimPenetrationChange >= 0.1
                "
                class-name="tendency_arrow_rise"
                class="icon rise"
              >
              </svg-icon>
              <svg-icon
                v-else-if="
                  row.verbalClaimPenetrationChange < 0 &&
                  row.verbalClaimPenetrationChange <= -0.1
                "
                class-name="tendency_arrow_reduce"
                class="icon reduce"
              >
              </svg-icon>
              <span v-else></span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table-column>
      <el-table-column label="数字宣称" align="center">
        <el-table-column label="效果指数" align="center">
          <el-table-column
            width="162"
            prop="numericClaimEffectivenessP1"
            align="center"
          >
            <template #default="{ row }">
              <div v-if="row.numericClaimEffectivenessP1.startsWith('P')">
                {{ row.numericClaimEffectivenessP1 }}
              </div>
              <div
                class="allege number_allege"
                v-else-if="row.numericClaimEffectivenessP1 > 1.05"
              >
                <svg-icon class-name="comparison" class="icon"> </svg-icon>
              </div>
              <div v-else></div>
            </template>
          </el-table-column>
          <el-table-column
            width="162"
            prop="numericClaimEffectivenessP2"
            align="center"
          >
            <template #default="{ row }">
              <div v-if="row.numericClaimEffectivenessP2.startsWith('P')">
                {{ row.numericClaimEffectivenessP2 }}
              </div>
              <div
                class="allege number_allege"
                v-else-if="row.numericClaimEffectivenessP2 > 1.05"
              >
                <svg-icon class-name="comparison" class="icon"> </svg-icon>
              </div>
              <div v-else></div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="提及率变化">
          <el-table-column
            width="106"
            prop="numericClaimPenetrationChange"
            align="center"
          >
            <template #default="{ row }">
              <svg-icon
                v-if="
                  row.numericClaimPenetrationChange > 0 &&
                  row.numericClaimPenetrationChange >= 0.1
                "
                class-name="tendency_arrow_rise"
                class="icon rise"
              >
              </svg-icon>
              <svg-icon
                v-else-if="
                  row.numericClaimPenetrationChange < 0 &&
                  row.numericClaimPenetrationChange <= -0.1
                "
                class-name="tendency_arrow_reduce"
                class="icon reduce"
              >
              </svg-icon>
              <div v-else></div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const props = defineProps({
  resourceData: {
    type: Array,
    default() {
      return [];
    },
  },
  defaultValue: {
    type: Object,
    default: () => {},
  },
  notHaveNumeric: {
    type: Array,
    default() {
      return [];
    },
  },

  loading: {
    type: Boolean,
    default: false,
  },
});

const arraySpanMethod = ({ row, rowIndex, columnIndex }) => {
  row.claimLevel1 = row.claimLevel1.replace("||", "\n");
  row.claimLevel2 = row.claimLevel2.replace("||", "\n");
  row.numericClaimEffectivenessP1 = row.numericClaimEffectivenessP1.replace(
    "||",
    "\n"
  );
  row.numericClaimEffectivenessP2 = row.numericClaimEffectivenessP2.replace(
    "||",
    "\n"
  );
  row.verbalClaimEffectivenessP1 = row.verbalClaimEffectivenessP1.replace(
    "||",
    "\n"
  );
  row.verbalClaimEffectivenessP2 = row.verbalClaimEffectivenessP2.replace(
    "||",
    "\n"
  );
  if (columnIndex === 0) {
    //过滤出一级宣称相同的数据
    const sameRoutIdList = props.resourceData.filter(
      (i) => i.claimLevel1 === row.claimLevel1
    );
    //找到一级宣称相同数据的第一个下标
    const firstIndex = props.resourceData.findIndex(
      (i) => i.claimLevel1 === row.claimLevel1
    );
    //合并单元格
    if (sameRoutIdList.length > 1) {
      if (rowIndex === firstIndex) {
        return {
          rowspan: sameRoutIdList.length,
          colspan: 1,
        };
      } else {
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
    } else {
      return {
        rowspan: 1,
        colspan: 1,
      };
    }
  }
};

//表头样式
const headerCellStyle = ({ rowIndex, columnIndex }) => {
  if (rowIndex === 0 && columnIndex === 0) {
    return "border-bottom:0;background-color:#fff;";
  }
  if (rowIndex === 1 && columnIndex === 0) {
    return "background-color:#fff;";
  }
  if (rowIndex === 0 && (columnIndex === 1 || columnIndex === 2)) {
    return "background-color:#C7D3E4;";
  }
  if (rowIndex === 2) {
    return "display:none";
  }
};

//单元格样式
const cellStyle = ({ row, rowIndex, columnIndex }) => {
  if (columnIndex === 1 && rowIndex !== 0) {
    return "background-color:#F6F8FA;padding:4px 0";
  }

  const result = [];
  props.notHaveNumeric.forEach((item) => {
    const variable = item.split("||");
    if (
      row.claimLevel1.startsWith(variable[0]) &&
      row.claimLevel2.startsWith(variable[1])
    ) {
      result.push({
        claimLevel1: variable[0],
        claimLevel2: variable[1],
      });
    }
  });
  if (result.length) {
    if (columnIndex == 5 || columnIndex == 6 || columnIndex == 7) {
      return "background-color:#DFDFDF;border:0";
    }
  }
  if (rowIndex !== 0) {
    return "padding:4px 0";
  }
};

//点击行
const cellClick = (row) => {
  if (row.claimLevel1 === "一级宣称" && row.claimLevel2 === "二级宣称") return;
  router.push({
    path: "/trendTrackingDetails",
    query: {
      category: props.defaultValue.category,
      productType: props.defaultValue.productType,
      claim: row.claimLevel1 + "||" + row.claimLevel2,
    },
  });
};
</script>

<style lang="scss" scoped>
.allege {
  margin: 0 auto;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #32a3be;
  font-size: 20px;
}

.allege.number_allege {
  color: #f56c6c;
}

.reduce {
  font-size: 20px;
  color: #f56c6c;
}

.rise {
  font-size: 20px;
  color: #67c23a;
}
</style>
<style lang="scss">
.el-table.trend_tracking_table {
  table.el-table__header {
    width: 100% !important;
  }

  .cell {
    padding: 0 4px;
    white-space: pre-line !important;
  }

  // .el-table .el-table__cell{
  //   padding: 4px 0!important;
  // }
}
</style>
